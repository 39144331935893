@use '@/css/global/mixins';
@use '@/css/global/variables';

body#page-entry-confirm {
    main {
        @include mixins.max-screen(variables.$breakpoint-mobile) {
            padding: 0 47px;
            h2 + p {
                margin-top: 20px;
            }
        }
    }
    .sec-confirm {
        max-width: 530px;
        margin: 45px auto 0;
        @include mixins.max-screen(variables.$breakpoint-mobile) {
            margin: 32px auto 0;
        }
        h2 {
            font-size: 1.6rem;
            background-color: #fff;
            color: #000;
            padding: 10px 12px;
            border-radius: 5px;
            line-height: 1;
            text-align: left;
        }
        &__box {
            margin-top: 17px;
            .item {
                display: flex;
                font-size: 1.2rem;
                letter-spacing: 0.025em;
                margin-left: 12px;
                @include mixins.max-screen(variables.$breakpoint-mobile) {
                    display: block;
                }
                + .item {
                    margin-top: 15px;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        margin-top: 13px;
                    }
                }
            }
            .list {
                width: 196px;
                margin-right: 23px;
                + p {
                    font-weight: 400;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        margin-top: 4px;
                        margin-left: 19px;
                    }
                }
            }
        }
    }
    .sec-entry {
        max-width: 530px;
        margin: 78px auto 0;
        font-size: 1.2rem;
        @include mixins.max-screen(variables.$breakpoint-mobile) {
            margin: 40px auto 0;
        }
        h2 {
            font-size: 1.6rem;
            background-color: #fff;
            color: #000;
            padding: 10px 12px;
            border-radius: 5px;
            line-height: 1;
            text-align: left;
            margin-bottom: 26px;
        }
        section + section {
            padding-top: 45px;
            border-top: 1px dashed #fff;
            border-bottom: 1px dashed #fff;
            @include mixins.max-screen(variables.$breakpoint-mobile) {
                padding-top: 48px;
            }
        }
        h3 {
            font-size: 1.5rem;
            letter-spacing: 0.02em;
            color: variables.$color-key;
            margin-left: 12px;
            @include mixins.max-screen(variables.$breakpoint-mobile) {
                margin-left: 0;
            }
        }
        &__box {
            margin-top: 15px;
        }
        &__head {
            margin-left: 12px;
            display: flex;
            font-weight: 400;
            margin-bottom: 25px;
            @include mixins.max-screen(variables.$breakpoint-mobile) {
                margin-bottom: 15px;
                margin-left: 0;
            }
            .list {
                font-weight: 600;
                margin-right: 18px;
            }
        }
        &__contents {
            border-top: 1px dashed #fff;
            padding: 30px 10px 23px;
            display: flex;
            @include mixins.max-screen(variables.$breakpoint-mobile) {
                align-items: flex-start;
                padding: 18px 0 23px;
            }
            .number {
                width: 34px;
                margin-top: -10px;
                @include mixins.max-screen(variables.$breakpoint-mobile) {
                    width: 22px;
                    margin-top: 0;
                }
            }
        }
        &__detail {
            width: calc(100% - 34px);
            .item {
                display: flex;
                align-items: center;
                @include mixins.max-screen(variables.$breakpoint-mobile) {
                    display: block;
                }
                + .item {
                    margin-top: 18px;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        margin-top: 16px;
                    }
                }
            }
            .list {
                width: 115px;
                margin-right: 30px;
                + p {
                    font-weight: 400;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        margin-left: 19px;
                        margin-top: 4px;
                    }
                }
            }
            .photo {
                display: flex;
                align-items: center;
                font-weight: 400;
                @include mixins.max-screen(variables.$breakpoint-mobile) {
                    margin-top: 5px;
                    margin-left: 19px;
                }
                div {
                    width: 73px;
                    margin-right: 20px;
                }
            }
        }
    }
    .button-box {
        display: flex;
        max-width: 590px;
        margin: 50px auto 0;
        column-gap: 30px;
        @include mixins.max-screen(variables.$breakpoint-mobile) {
            flex-direction: column;
            row-gap: 30px;
        }
        div {
            width: 280px;
            max-width: 100%;
            margin: 0 auto;
            text-align: center;
            line-height: 48px;
            position: relative;
            @include mixins.mq_sp() {
                width: 100%;
            }
            > p {
                font-size: 1.5rem;
                font-weight: variables.$font-weight-bold;
                line-height: 1.6em;
                margin-top: 15px;
                letter-spacing: -0.01em;
                text-align: center;
                word-break: keep-all;
            }
            button {
                color: variables.$color-font-base;
                width: 100%;
                border-radius: 55px;
                font-size: 1.5rem;
                letter-spacing: 0.04em;
                position: relative;
            }
            &.back {
                button {
                    background-color: #9d9d9d;
                    &::after {
                        content: "";
                        background: url(~img/back-arrow.svg) center center
                            no-repeat;
                        width: 11px;
                        height: 7px;
                        background-size: contain;
                        position: absolute;
                        top: 50%;
                        left: 17px;
                        transform: translateY(-50%);
                    }
                }
            }
            &.confirm {
                button {
                    background-color: variables.$color-key;
                    transition: all 0.3s;
                    &::after {
                        content: "";
                        background: url(~img/next-arrow.svg) center center
                            no-repeat;
                        width: 11px;
                        height: 7px;
                        background-size: contain;
                        position: absolute;
                        top: 50%;
                        right: 17px;
                        transform: translateY(-50%);
                    }
                    &.is-loading {
                        background-color: rgba(variables.$color-key, 0.6);
                        color: rgba(#fff, 0.6);
                        pointer-events: none;
                        &::before {
                            content: "";
                            background: url(~img/loading.svg) center center
                                no-repeat;
                            background-size: 100% 80%;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}
