$color-font-base: #FFFFFF;
$color-key: #C32E76;
$color-sub: #D2B48C;
$color-acc: #D2B48C;
$color-back: #000;
$color-orange: #E87400;

$keycolor: #359f82;
$keycolor-red: #ff9999;
$keycolor-pink: #e61673;
$button-color: #6495ed;
$base-gradient: linear-gradient(to top right, #CC6080 0%, #7C74AD 100%);