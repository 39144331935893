@use '@/css/global/mixins';
@use '@/css/global/variables';

body#page-entry-complete{
    @include mixins.max-screen(variables.$breakpoint-mobile) {
        h2 + p {
            margin-top: 19px;
        }
    }
    .sec-complete {
        margin-top: 88px;
        @include mixins.max-screen(variables.$breakpoint-mobile) {
            margin-top: 48px;
        }
        &__read {
            text-align: center;
            font-size: 1.5rem;
            letter-spacing: .02em;
            line-height: 2;
            font-weight: bold;
        }
        div {
            max-width: 740px;
            margin: 47px auto 0;
            padding: 0 30px;
            font-size: 1.4rem;
            letter-spacing: .01em;
            line-height: 1.7;
            font-weight: 400;
            @include mixins.max-screen(variables.$breakpoint-mobile) {
                padding: 0 25px;
            }
            p + p {
                margin-top: 24px;
            }
        }
    }
}