@use '@/css/global/variables' as *;
@use '@/css/global/mixins' as *;

#modal{
  .modal-content-wrap {
    position: relative;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s ease;
    max-width: 90vw;
    max-height: 80vh;
    background-color: #fff;
    position: fixed;
    left: 50vw;
    top: 50vh;
    transform: translate(-50%, -55%);
  }
  .modal-overlay {
    background-color: rgba(#000000,0.5);
    position: fixed;
    left: 0;
    top:0;
    min-width: 100vw;
    min-height: 100vh;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s ease;
  }
  .modal-close{
    position: absolute;
    right:15px;
    top:15px;
    width: 16px;
    height: 16px;
    background: url(../../img/ico-close-gray.svg) center center no-repeat;
    background-size: 16px auto;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }
  &.active{
    .modal-content-wrap {
      visibility: visible;
      opacity: 1;
      z-index: 999;
    }
    .modal-overlay {
      visibility: visible;
      opacity: 1;
      z-index: 999;
    }
  }
  .modal-content{
    background-color: #fff;
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling : touch;
    > *{
      width: 1000px;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}



// front
.modal-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba(0,0,0,50%);
    padding: 40px 20px;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    box-sizing: border-box;
    .modal-inner{
        background: #fff;
        text-align: left;
        padding: 24px 30px 29px;
        border-radius: 10px;
        margin: 92px auto;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        max-width: 660px;
        left: 0;
        color: #000000;
        @include mq_sp() {
            padding: 24px 27px 24px;
            margin: 30px auto;
        }
    }
    .modal-close {
        position: absolute;
        top: -35px;
        right: 0;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
    
    .modal-content {
        h2 {
            font-size: 1.8rem;
            letter-spacing: .069em;
        }
        ul {
            margin-top: 15px;
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 1.76;
            letter-spacing: .05em;
            @include mq_sp() {
                font-size: 1.2rem;
                line-height: 1.86;
            }
            li + li {
                margin-top: 23px;
                @include mq_sp() {
                    margin-top: 21px;
                }
            }
            .title {
                font-weight: bold;
            }
        }
    }
    &.active {
    opacity: 1;
    visibility: visible;
    }
}
