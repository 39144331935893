@use '@/css/global/mixins';
@use '@/css/global/variables';
@forward '@/css/foundation';

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;600;700&display=swap");

@font-face {
    font-family: "DIN";
    src: url("~webfonts/din.woff2") format("woff2"),
        url("~webfonts/din.woff") format("woff");
}

body {
    font-size: 1.6rem;
    line-height: 1.5;
    color: variables.$color-font-base;
    font-weight: 600;
    letter-spacing: 0.05em;
    font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", sans-serif;
    background-color: variables.$color-back;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
    min-height: 100dvh;
}

a {
    color: variables.$color-font-base;
    text-decoration: none;
    outline: none;
    transition: all 0.4s ease;
    &:hover {
        opacity: 0.6;
    }
}

body > header {
    padding-top: 53px;
    @include mixins.max-screen(variables.$breakpoint-mobile) {
        padding-top: 30px;
    }
    .logo {
        width: 155px;
        margin: 0 auto;
        @include mixins.max-screen(variables.$breakpoint-mobile) {
            width: 85px;
        }
    }
}

main {
    width: 1000px;
    max-width: 100%;
    margin: 90px auto 28px;
    padding: 0 30px;
    @include mixins.max-screen(variables.$breakpoint-mobile) {
        margin: 24px auto 18px;
        padding: 0 25px;
    }
    h2 {
        font-size: 2.4rem;
        letter-spacing: 0.05em;
        line-height: 2;
        text-align: center;
        + p {
            font-size: 18px;
            margin-top: 8px;
            letter-spacing: 0.069em;
            font-weight: bold;
            line-height: 1.67;
            text-align: center;
            @include mixins.max-screen(variables.$breakpoint-mobile) {
                letter-spacing: 0.05em;
            }
        }
    }
}

body > footer {
    margin-top: 50px;
    margin-bottom: 35px;
    @include mixins.max-screen(variables.$breakpoint-mobile) {
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .environment {
        margin-inline: auto;
        font-size: 1.3rem;
        width: fit-content;
        border-bottom: 1px solid #fff;
    }
    .copyright {
        font-size: 1.1rem;
        letter-spacing: 0.02em;
        text-align: center;
        font-weight: 400;
        margin-top: 20px;
    }
}
