@use '@/css/global/variables';

@mixin max-screen($break-point) {
    @media screen and (max-width: $break-point) {
        @content;
    }
}

@mixin min-screen($break-point) {
    @media screen and (min-width: $break-point) {
        @content;
    }
}

@mixin screen($break-point-min, $break-point-max) {
    @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
        @content;
    }
}

@mixin mq_sp {
    @include max-screen(variables.$breakpoint-mobile) {
        @content;
    }
}

@mixin mq_tab {
    @include screen(variables.$breakpoint-mobile, variables.$breakpoint-tablet) {
        @content;
    }
}

@mixin mq_max_tab {
    @include max-screen(variables.$breakpoint-tablet) {
        @content;
    }
}

@mixin mq_pc_content {
    @include max-screen(variables.$breakpoint-pc-content) {
        @content;
    }
}