$font-noto-sans: 'Noto Sans JP', sans-serif;
$font-noto-serif: "Noto Serif JP", serif;
$font-din: 'DIN';
// font weights
$font-weight-thin: 100;
$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;