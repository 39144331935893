@use '@/css/global/variables' as *;
@use '@/css/global/mixins' as *;

html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  line-height: 160%;
  color: #000;
  font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "verdana", sans-serif;
    @include mq_sp {
      width: 100%;
      overflow-x: hidden;
    }
}

a {
  color: #000;
  text-decoration: none;
  outline: none;
  img{
    transition: opacity 0.2s ease, transform 0.4s ease;
  }
  &:hover{
    img{
      opacity: 0.8;
      &.scale{
        transform: scale(1.1);
      }
    }
  }
}

img, video {
  max-width: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout:none;
}

ul{
  list-style: none;
}

button{
  outline: none;
}
// PCでのTELリンク無効
@include min-screen($breakpoint-mobile) {
  a[href^='tel:'] {
    pointer-events: none;
  }
}

.pc-only, ._pc{
  display: inline;
  @include mq_sp {
    display: none;
  }
}
.sp-only, ._sp{
  display: none;
  @include mq_sp {
    display: inline;
  }
}
.text-bold{
  font-weight: bold;
}
.weight-normal{
  font-weight: normal;
}

.text-linethrough{
  text-decoration: line-through;
}