@use '@/css/global/mixins';
@use '@/css/global/variables';

body#page-entry-input-step02 {
    label.checkbox input[type="checkbox"] + *:after {
        border-width: 1px;
    }
    .sec-step02 {
        margin-top: 93px;
        @include mixins.max-screen(variables.$breakpoint-mobile) {
            margin-top: 34px;
        }
        .block-entry {
            display: none;
            &:has(.item-box) {
                display: block;
            }
        }

        .item-box-wrapper {
            counter-reset: number;
            .item-box__number {
                counter-increment: number;
                &:before {
                    content: counter(number);
                }
            }
        }
        &__head {
            text-align: center;
            color: variables.$color-key;
            font-size: 1.8rem;
            letter-spacing: 0.03em;
            font-weight: bold;
            font-family: variables.$font-din;
            div {
                width: 65px;
                margin: 0 auto;
            }
        }
        &__read {
            margin-top: 11px;
            line-height: 1.66;
            font-size: 18px;
            text-align: center;
            font-weight: bold;
            letter-spacing: 0.1em;
            + p {
                text-align: center;
                margin-top: 20px;
                font-size: 1.2rem;
                letter-spacing: 0.05em;
                @include mixins.max-screen(variables.$breakpoint-mobile) {
                    margin-top: 9px;
                }
            }
        }
        &__form {
            margin: 45px auto 0;
            @include mixins.max-screen(variables.$breakpoint-mobile) {
                padding: 0 22px;
                margin: 56px auto 0;
            }
            &-head {
                max-width: 530px;
                margin: 0 auto;
                font-size: 1.5rem;
                font-weight: bold;
                letter-spacing: 0.02em;
                ul {
                    margin-top: 5px;
                    li {
                        font-size: 1.2rem;
                        padding-left: 15px;
                        position: relative;
                        color: #c1c1c1;
                        letter-spacing: 0.05em;
                        &::before {
                            content: "※";
                            font-size: 1.2rem;
                            position: absolute;
                            left: 0;
                        }
                    }
                }
            }
            &-inner {
                max-width: 530px;
                margin: 0 auto;
            }
            .item {
                > label {
                    &.hide {
                        display: none;
                    }
                    p {
                        display: inline-block;
                    }
                    span {
                        font-size: 0.9rem;
                        display: inline-block;
                        margin-left: 5px;
                        vertical-align: middle;
                    }
                }
            }
            .first-item {
                @include mixins.max-screen(variables.$breakpoint-mobile) {
                    margin-bottom: 40px;
                }
                &.col-box {
                    display: flex;
                    column-gap: 30px;
                    margin-top: 25px;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        flex-direction: column;
                        column-gap: 0;
                        row-gap: 24px;
                    }
                    > * {
                        width: 100%;
                    }
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        margin-top: 21px;
                        p {
                            letter-spacing: 0.2em;
                        }
                    }
                }
                .col {
                    label {
                        p {
                            font-size: 1.4rem;
                            display: inline-block;
                            @include mixins.max-screen(
                                variables.$breakpoint-mobile
                            ) {
                                letter-spacing: 0.02em;
                            }
                        }
                        .span {
                            display: inline-block;
                            margin-left: 5px;
                            font-size: 0.9rem;
                        }
                    }
                }
            }
            &-title {
                color: variables.$color-key;
                font-size: 1.5rem;
                letter-spacing: 0.02em;
                margin-top: 34px;
                padding-top: 47px;
                border-top: 1px dashed #fff;
                @include mixins.max-screen(variables.$breakpoint-mobile) {
                    margin-top: 17px;
                    padding-top: 40px;
                }
                + p {
                    margin-top: 7px;
                    letter-spacing: 0.05em;
                    font-size: 1.2rem;
                    padding-left: 18px;
                    position: relative;
                    color: #c1c1c1;
                    &::before {
                        content: "※";
                        font-size: 1.2rem;
                        position: absolute;
                        left: 0;
                    }
                }
            }
            .item-box {
                display: flex;
                margin-top: 37px;
                @include mixins.max-screen(variables.$breakpoint-mobile) {
                    margin-top: 35px;
                }
                + .item-box {
                    border-top: 1px dashed #fff;
                    margin-top: 18px;
                    padding-top: 36px;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        margin-top: 4px;
                        padding-top: 24px;
                    }
                }
                > div {
                    width: 100%;
                    + div {
                        margin-left: 30px;
                        @include mixins.max-screen(
                            variables.$breakpoint-mobile
                        ) {
                            margin-left: 0;
                        }
                    }
                }
                &__number {
                    font-size: 1.5rem;
                    margin-right: 24px;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        margin-right: 18px;
                    }
                }
                .item {
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        font-size: 1.3rem;
                        margin-bottom: 19px;
                    }
                }
            }
            .check-box {
                margin-top: 20px;
                padding-top: 44px;
                border-top: 1px dashed #fff;
                font-size: 1.3rem;
                font-weight: 400;
                @include mixins.max-screen(variables.$breakpoint-mobile) {
                    margin-top: 0;
                    padding-top: 30px;
                }
                h3 {
                    color: #fff;
                    font-size: 1.4rem;
                    letter-spacing: 0.1em;
                    margin-bottom: 8px;
                    line-height: 1.78;
                }
                ul {
                    margin-bottom: 30px;
                    li {
                        font-size: 1.3rem;
                        line-height: 1.9;
                        padding-left: 23px;
                        position: relative;
                        @include mixins.max-screen(
                            variables.$breakpoint-mobile
                        ) {
                            font-weight: 400;
                        }
                        &::before {
                            content: "※";
                            font-size: 1.3rem;
                            position: absolute;
                            left: 0;
                        }
                    }
                }
                .check-item {
                    display: flex;
                    input[type="checkbox"] {
                        position: relative;
                        width: 18px;
                        height: 18px;
                        background-color: #ebebeb;
                        border: none;
                        vertical-align: -5px;
                        appearance: none;
                        cursor: pointer;
                        margin-right: 9px;
                    }

                    input[type="checkbox"]:checked {
                        background-color: variables.$color-key;
                        &::before {
                            position: absolute;
                            top: 2px;
                            left: 5px;
                            transform: rotate(50deg);
                            width: 7px;
                            height: 12px;
                            border-right: 3px solid #fff;
                            border-bottom: 3px solid #fff;
                            content: "";
                        }
                    }
                }
                .policy {
                    margin-top: 27px;
                    margin-left: 27px;
                    margin-bottom: 12px;
                    font-size: 1.2rem;
                    line-height: 1.8;
                    letter-spacing: 0.05em;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        font-weight: 400;
                    }
                    a {
                        display: block;
                        font-size: 1.2rem;
                        text-decoration: underline;
                        margin-top: 7px;
                        cursor: pointer;
                        img {
                            vertical-align: middle;
                        }
                    }
                }
                + .check-box {
                    margin-top: 48px;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        margin-top: 25px;
                    }
                }
            }
            .item {
                font-size: 1.5rem;
                font-weight: bold;
                letter-spacing: 0.02em;
                margin-bottom: 15px;
                .file-up {
                    margin-top: 8px;
                    display: inline-block;
                    position: relative;
                    width: 100%;
                    height: 40px;
                    line-height: 30px;
                    padding: 5px 15px 5px 43px;
                    border-radius: 5px;
                    background-color: #fff;
                    font-weight: 400;
                    font-size: 1.3rem;
                    letter-spacing: 0.02em;
                    color: #000000;
                    cursor: pointer;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        margin-top: 2px;
                    }
                    &:has(+ .preview.is-selected) {
                        display: none;
                    }
                    &::before {
                        content: "";
                        background: url(~img/feather-image.svg) center center
                            no-repeat;
                        width: 19px;
                        height: 19px;
                        background-size: contain;
                        position: absolute;
                        top: 50%;
                        left: 15px;
                        transform: translateY(-50%);
                    }
                    input[type="file"] {
                        display: none;
                    }
                }
                .preview {
                    display: none;
                    margin-top: 7px;
                    position: relative;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        margin-top: 4px;
                    }
                    &:after {
                        content: "";
                        background: url(~img/loading.svg) rgba(0, 0, 0, 0.6)
                            center center no-repeat;
                        background-size: 50% 50%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        opacity: 0;
                        pointer-events: none;
                        transition: opacity 0.2s ease;
                    }
                    &.is-selected {
                        display: flex;
                    }
                    &.is-uploading {
                        &:after {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                    .photo {
                        width: calc(100% - 42px);
                        background-color: #ededed;
                        padding: 5px;
                        margin-right: 5px;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        font-size: 1.3rem;
                        font-weight: 400;
                        color: #000000;
                        @include mixins.max-screen(
                            variables.$breakpoint-mobile
                        ) {
                            width: calc(100% - 34px);
                            margin-right: 7px;
                        }
                        figure {
                            width: 73px;
                            margin-right: 5px;
                        }
                    }
                    .icon {
                        width: 42px;
                        @include mixins.max-screen(
                            variables.$breakpoint-mobile
                        ) {
                            width: 36px;
                        }
                        div,
                        label {
                            display: block;
                            background-color: #ededed;
                            padding: 12px 13px;
                            border-radius: 5px;
                            height: 42px;
                            cursor: pointer;
                            @include mixins.max-screen(
                                variables.$breakpoint-mobile
                            ) {
                                padding: 12px 10px;
                            }
                            + div {
                                margin-top: 4px;
                            }
                        }
                    }
                }
                span {
                    font-size: 0.9rem;
                    display: inline-block;
                    margin-left: 7px;
                    vertical-align: middle;
                }
                .input-box {
                    margin-top: 7px;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        margin-top: 3px;
                    }
                    input {
                        width: 100%;
                        border-radius: 5px;
                        padding: 5px 15px;
                        height: 43px;
                        background-color: #fff;
                        font-size: 1.3rem;
                        font-weight: 500;
                        @include mixins.max-screen(
                            variables.$breakpoint-mobile
                        ) {
                            height: 38px;
                            font-size: 1.6rem;
                        }
                        &.number {
                            max-width: 260px;
                            width: 50%;
                            @include mixins.max-screen(
                                variables.$breakpoint-mobile
                            ) {
                                max-width: unset;
                                width: 100%;
                            }
                        }
                    }
                    textarea {
                        width: 100%;
                        border-radius: 5px;
                        padding: 5px 15px;
                        height: 90px;
                        background-color: #fff;
                        font-size: 1.3rem;
                        font-weight: 500;
                        @include mixins.max-screen(variables.$breakpoint-mobile) {
                            font-size: 1.6rem;
                        }
                    }
                }
                .select-box {
                    position: relative;
                    width: 100%;
                    max-width: 260px;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        max-width: 100%;
                    }
                    .input-select {
                        display: block;
                        &::after {
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-right: 6px solid transparent;
                            border-left: 6px solid transparent;
                            border-top: 10px solid #000000;
                            border-bottom: 0;
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            transform: translateY(-50%);
                            pointer-events: none;
                        }
                    }
                    select {
                        width: 100%;
                        border-radius: 5px;
                        padding: 5px 25px 5px 15px;
                        height: 43px;
                        background-color: #fff;
                        font-size: 1.3rem;
                        font-weight: 500;
                        letter-spacing: 0.02em;
                        color: #000;
                        @include mixins.max-screen(
                            variables.$breakpoint-mobile
                        ) {
                            width: 100%;
                            max-width: unset;
                            font-size: 1.6rem;
                        }
                    }
                }
            }
            .button-box {
                max-width: 590px;
                display: flex;
                margin: 75px auto 0;
                @include mixins.max-screen(variables.$breakpoint-mobile) {
                    flex-direction: column-reverse;
                    margin-top: 30px;
                }
                div {
                    width: 280px;
                    max-width: 100%;
                    height: 48px;
                    margin: 0 auto;
                    text-align: center;
                    line-height: 48px;
                    position: relative;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        width: 100%;
                    }
                    button {
                        color: variables.$color-font-base;
                        width: 100%;
                        border-radius: 55px;
                        font-size: 1.5rem;
                        letter-spacing: 0.04em;
                        position: relative;
                    }
                    + div {
                        margin-left: 30px;
                        @include mixins.max-screen(
                            variables.$breakpoint-mobile
                        ) {
                            margin: 0 auto 20px;
                        }
                    }
                    &.back {
                        button {
                            background-color: #9d9d9d;
                            margin: 0;
                            &::after {
                                content: "";
                                background: url(~img/back-arrow.svg) center
                                    center no-repeat;
                                width: 11px;
                                height: 7px;
                                background-size: contain;
                                position: absolute;
                                top: 50%;
                                left: 17px;
                                transform: translateY(-50%);
                            }
                        }
                    }
                    &.confirm {
                        button {
                            background-color: variables.$color-key;
                            margin: 0;
                            &::after {
                                content: "";
                                background: url(~img/next-arrow.svg) center
                                    center no-repeat;
                                width: 11px;
                                height: 7px;
                                background-size: contain;
                                position: absolute;
                                top: 50%;
                                right: 17px;
                                transform: translateY(-50%);
                            }
                        }
                    }
                    .button[type="button"][disabled] {
                        background-color: #9d9d9d;
                    }
                }
            }
            .error-message {
                text-align: left;
                margin-top: 5px;
                color: #fff;
                > span {
                    margin-left: 0;
                }
            }
        }
    }
    .modal-content-wrap {
        border-radius: 10px;
    }
    .modal-content {
        background-color: transparent;
    }
    .modal-privacy {
        color: black;
        padding: 40px 20px;
        h2 {
            font-size: 1.8rem;
            letter-spacing: 0.069em;
        }
        ul {
            margin-top: 15px;
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 1.76;
            letter-spacing: 0.05em;
            @include mixins.mq_sp() {
                font-size: 1.2rem;
                line-height: 1.86;
            }
            li + li {
                margin-top: 23px;
                @include mixins.mq_sp() {
                    margin-top: 21px;
                }
            }
            .title {
                font-weight: bold;
            }
        }
    }
}
