@use '@/css/global/variables' as *;
@use '@/css/global/mixins' as *;

$borderColor: #000;

main,
#modal {
    input[type="submit"],
    input[type="button"] {
        border-radius: 0;
        -webkit-box-sizing: content-box;
        -webkit-appearance: button;
        appearance: button;
        border: none;
        box-sizing: border-box;
        &::-webkit-search-decoration {
            display: none;
        }
        &:focus {
            outline-offset: -2px;
        }
    }

    input[type="text"],
    input[type="button"],
    input[type="email"],
    input[type="submit"],
    input[type="password"],
    input[type="number"],
    textarea {
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-tap-highlight-color: rgba(#000, 0);
        box-sizing: border-box;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="number"] {
        border: 1px solid $borderColor;
        line-height: 2rem;
        padding: 5px;
        //border-radius: 3px;
        max-width: 100%;
        background-color: #fff;

        &.small {
            width: 200px;
            max-width: 100%;

            @include mq_sp {
                width: 100px;
            }
        }
        &.small-medium {
            width: 200px;
            max-width: 100%;
        }
        &.s-small {
            width: 100px;
            max-width: 100%;
        }

        &.medium {
            width: 500px;
            max-width: 100%;

            @include mq_sp {
                width: 300px;
            }
        }

        &.full {
            width: 100%;
        }
    }

    textarea {
        width: 100%;
        padding: 5px;
        box-sizing: border-box;
        border: 1px solid $borderColor;
        //border-radius: 3px;
    }

    select {
        border: 1px solid $borderColor;
    }

    input[type="checkbox"] {
        display: none;

        &:checked + label:before {
            opacity: 1;
        }
    }

    label.checkbox {
        position: relative;
        display: inline-block;
        cursor: pointer;
        margin-right: 10px;
        &:only-child{
            margin-right: 0;
        }

        input[type="checkbox"] {
            display: none;

            &:checked {
                + *:before {
                    opacity: 1;
                }
                + *:after {
                    background-color: #999;
                }
            }

            & + * {
                padding-left: 25px;

                &:after {
                    transition: border-color 0.1s linear;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    display: block;
                    margin-top: -10px;
                    width: 20px;
                    height: 20px;
                    border: 2px solid #999;
                    background-color: #fff;
                    border-radius: 0;
                    content: "";
                }

                &:before {
                    transition: opacity 0.1s linear;
                    position: absolute;
                    top: 50%;
                    left: 7px;
                    display: block;
                    margin-top: -7px;
                    width: 6px;
                    height: 11px;
                    border-right: 3px solid #fff;
                    border-bottom: 3px solid #fff;
                    content: "";
                    opacity: 0;
                    transform: rotate(45deg);
                    z-index: 1;
                }
            }
        }
    }

    label.radio {
        position: relative;
        display: inline-block;
        cursor: pointer;
        margin-right: 10px;

        input[type="radio"] {
            display: none;

            &:checked + *:before {
                opacity: 1;
            }
            &:disabled{
                + * {
                    &:after {
                        background-color: #ccc;
                        border: 2px solid #ccc;
                    }
                }
            }

            & + * {
                padding-left: 25px;
                display: block;
                &:after {
                    transition: border-color 0.1s linear;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    display: block;
                    margin-top: -10px;
                    width: 20px;
                    height: 20px;
                    border: 2px solid #bbb;
                    border-radius: 20px;
                    background-color: #fff;
                    content: "";
                    z-index: 0;
                }

                &:before {
                    transition: opacity 0.1s linear;
                    position: absolute;
                    top: 50%;
                    left: 5px;
                    display: block;
                    margin-top: -5px;
                    width: 10px;
                    height: 10px;
                    content: "";
                    opacity: 0;
                    border-radius: 10px;
                    background-color: #666;
                    z-index: 1;
                }
            }
        }
    }
    .multi_h label {
        position: relative;
        display: inline-block;
        cursor: pointer;
        margin-right: 10px;

        input[type="radio"] {
            display: none;

            &:checked + span:before {
                opacity: 1;
            }

            & + span {
                padding-left: 25px;

                &:after {
                    transition: border-color 0.1s linear;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    display: block;
                    margin-top: -10px;
                    width: 20px;
                    height: 20px;
                    border: 2px solid $borderColor;
                    border-radius: 20px;
                    content: "";
                }

                &:before {
                    transition: opacity 0.1s linear;
                    position: absolute;
                    top: 50%;
                    left: 5px;
                    display: block;
                    margin-top: -5px;
                    width: 10px;
                    height: 10px;
                    content: "";
                    opacity: 0;
                    border-radius: 10px;
                    background-color: $borderColor;
                }
            }
        }
    }

    .input-select,.input_select {
        position: relative;
        display: inline-block;

        &:after {
            position: absolute;
            top: 50%;
            right: 10px;
            width: 0;
            height: 0;
            padding: 0;
            content: "";
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid rgba(#000, 0.3);
            pointer-events: none;
            transform: translateY(-50%);
        }

        select {
            position: relative;
            font-family: inherit;
            background-color: transparent;
            padding: 5px 40px 5px 5px;
            font-size: 1.4rem;
            border-radius: 3px;
            border: none;
            border: 1px solid $borderColor;
            appearance: none;
            outline: none;
            background-color: #fff;
        }
    }
    label.file {
        display: inline-block;
        input {
            display: none;
        }
        > div, > button {
            background-color: #000;
            display: inline-block;
            line-height: 100%;
            padding: 5px 25px;
            border-radius: 40px;
            color: #fff;
            font-size: 1.4rem;
            cursor: pointer;
            width: 105px;
            text-align: center;
        }
    }
    .image, .video {
        .preview {
            position: relative;
            .del {
                display: none;
                position: absolute;
                left: 5px;
                top: 5px;
                font-size: 2rem;
                line-height: 100%;
                border-radius: 20px;
                background-color: #fff;
                padding: 2px;
                width: 22px;
                height: 22px;
                cursor: pointer;
                z-index: 1;
                &:hover {
                    svg{
                        opacity: 0.6;
                    }
                }
                svg{
                    position: absolute;
                    left: 1px;
                    top: 1px;
                }
            }
            .progress{
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
                width: calc(100% - 20px);
                height: 10px;
                display: block;
                background-color: rgba(#000, 0.6);
                margin: 0 10px;
                border-radius: 5px;
                z-index: 1;
                > span{
                    display: block;
                    width: 0;
                    height: 100%;
                    background-color: rgba(#fff, 0.8);
                    border-radius: 5px;
                }
            }
            figure{
                &.photo, &.video {
                    margin: 10px 0;
                    max-width: 300px;
                    line-height: 0;
                    position: relative;
                    @include mq_sp {
                        max-width: 100%;
                    }
                    &:before {
                        opacity: 0;
                        pointer-events: none;
                        transition: opacity 0.2s ease;
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        background: url(../../img/loading.svg) center center
                            no-repeat;
                        background-size: 30% auto;
                        background-color: rgba(#000, 0.5);
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                    }
                    &.uploading {
                        &:before {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                    + .del {
                    display: inline-block;
                    }
                }
                &.video{
                    max-width: 100%;
                }
            }
        }
    }
    button,
    input[type="submit"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 0;
        border: none;
        outline: none;
        background: transparent;
    }
    .multi_h {
        display: flex;
        flex-direction: column;
        > * {
            > label {
                display: block;
                font-size: 1.2rem;
                font-weight: bold;
            }
            + * {
                margin-top: 10px;
            }
        }
    }
    .multi_w {
        @extend .multi_h;
        flex-direction: row;
        flex-wrap: wrap;
        > * {
            margin-right: 20px;
            + * {
                margin-top: 0;
                //margin-left: 10px;
            }
        }
    }
    .item {
        margin-top: 20px;
        &.hide{
            display: none;
        }
        + .item {
            margin-top: 20px;
        }
        > label {
            font-weight: bold;
            font-size: 1.4rem;
            .required {
                color: red;
                display: inline-block;
                margin-left: 5px;
                font-size: 1.8rem;
            }
            + * {
                margin-top: 10px;
            }
        }
        .error-message {
            margin-top: 3px;
            span {
                background-color: lightcoral;
                color: #fff;
                font-size: 1.1rem;
                line-height: 100%;
                padding: 2px 10px;
                display: inline-block;
                line-height: 1.6rem;
                border-radius: 3px;
            }
        }
    }
    .area-submit {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 20px 0;
        .error-count {
            width: 100%;
        }
        button {
            background-color: $button-color;
            color: #fff;
            font-size: 1.4rem;
            width: 200px;
            padding: 5px 10px;
            box-sizing: border-box;
            margin: 5px 10px;
            &.button-draft{
                background-color: #aaa;
            }
        }
        + .delete {
            position: absolute;
            right: 20px;
            top: 15px;
            background-color: red;
            color: #fff;
            border-radius: 3px;
            font-size: 1.4rem;
            padding: 0 10px;
        }
    }
}
