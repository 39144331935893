@use '@/css/global/mixins';
@use '@/css/global/variables';

// 管理者＆審査ページ用
#app:has(#page-requirements){
    main{
        width: 1060px;
        max-width: 100%;
        margin: 28px auto;
        @include mixins.max-screen(variables.$breakpoint-mobile) {
            padding: 0 35px;
            margin: 35px auto;
        }
    }
}

#page-requirements{
    main {
        width: 1060px;
        max-width: 100%;
        margin: 28px auto;
        @include mixins.max-screen(variables.$breakpoint-mobile) {
            padding: 0 35px;
            margin: 35px auto;
        }
    }
    .breadcrumb {
        list-style: none;
        font-weight: 400;
        font-size: 1.2rem;
        display: flex;
        @include mixins.max-screen(variables.$breakpoint-mobile) {
            font-size: 1.1rem;
        }
        li {
            position: relative;
            width: fit-content;
            + li {
                margin-left: 18px;
                &::before {
                    content: ">";
                    position: absolute;
                    left: -12px;
                }
            }
        }
    }
    .sec-requirements {
        margin-top: 75px;
        margin-bottom: 100px;
        @include mixins.max-screen(variables.$breakpoint-mobile) {
            margin-top: 35px;
            margin-bottom: 80px;
        }
        h2 {
            text-align: center;
            @include mixins.max-screen(variables.$breakpoint-mobile) {
                font-size: 1.5rem;
            }
            + * {
                margin-top: 40px;
            }
        }
        &__read {
            font-size: 1.2rem;
            line-height: 1.8;
            font-weight: 400;
        }
        &__box {
            max-width: 600px;
            margin: 22px auto 0;
            letter-spacing: .05rem;
            @include mixins.max-screen(variables.$breakpoint-mobile) {
                margin: 32px auto 0;
                letter-spacing: unset;
            }
        }
        h3 {
            margin-top: 22px;
            font-size: 1.5rem;
        }
        div {
            line-height: 1.6;
            > p {
                font-size: 1.2rem;
            }
            li {
                font-size: 1.2rem;
                line-height: 1.8;
                font-weight: 400;
            }
            + div {
                margin-top: 24px;
            }
            + p {
                margin-top: 30px;
                font-size: 1.2rem;
                font-weight: 400;
            }
        }
    }
    footer .environment{
        display: none;
    }
}