@use '@/css/global/mixins';
@use '@/css/global/variables';

body#page-entry-input-step01{
    .sec-step01 {
        margin-top: 43px;
        &__head {
            text-align: center;
            color: variables.$color-key;
            font-size: 1.8rem;
            letter-spacing: .03em;
            font-weight: bold;
            font-family: variables.$font-din;
            div {
                width: 65px;
                margin: 0 auto;
            }
            + p {
                margin-top: 12px;
                font-size: 18px;
                text-align: center;
                font-weight: bold;
                letter-spacing: .1em;
                font-family: variables.$font-din;
                @include mixins.max-screen(variables.$breakpoint-mobile) {
                    margin-top: 10px;
                }
                + .lead-add{
                    font-size: 1.2rem;
                    color: #D60027;
                    text-align: center;
                    margin-top: 3px;
                    letter-spacing: 0;
                }
            }
        }
        &__form {
            max-width: 542px;
            margin: 42px auto 0;
            @include mixins.max-screen(variables.$breakpoint-mobile) {
                padding: 0 22px;
            }
            .item-box {
                display: flex;
                @include mixins.max-screen(variables.$breakpoint-mobile) {
                    display: block;
                }
                > div {
                    width: 50%;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        width: 100%;
                    }
                    + div {
                        margin-left: 30px;
                        @include mixins.max-screen(variables.$breakpoint-mobile) {
                            margin-left: 0;
                        }
                    }
                }
            }
            .item {
                font-size: 1.5rem;
                font-weight: bold;
                letter-spacing: .02em;
                margin-bottom: 14px;
                @include mixins.max-screen(variables.$breakpoint-mobile) {
                    margin-bottom: 23px;
                }
                > label{
                    &.hide{
                        display: none;
                    }
                    p{
                        display: inline-block;
                    }
                    span {
                        font-size: .9rem;
                        display: inline-block;
                        margin-left: 5px;
                        vertical-align: middle;
                    }
                }
                .input-box {
                    margin-top: 8px;
                    input {
                        width: 100%;
                        border-radius: 5px;
                        padding: 5px 15px;
                        height: 43px;
                        background-color: #fff;
                        font-size: 1.3rem;
                        font-weight: 500;
                        @include mixins.max-screen(variables.$breakpoint-mobile) {
                            font-size: 1.6rem;
                        }
                        &.short {
                            max-width: 256px;
                            width: 50%;
                            @include mixins.max-screen(variables.$breakpoint-mobile) {
                                max-width: unset;
                                width: 100%;
                            }
                        }
                    }
                }
                .select-box {
                    position: relative;
                    width: 50%;
                    max-width: 260px;
                    @include mixins.max-screen(variables.$breakpoint-mobile) {
                        width: 100%;
                        max-width: unset;
                    }
                    .input-select{
                        display: block;
                        &::after {
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-right: 6px solid transparent;
                            border-left: 6px solid transparent;
                            border-top: 10px solid #000000;
                            border-bottom: 0;
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            transform: translateY(-50%);
                            pointer-events: none;
                        }
                    }
                    select {
                        width: 100%;
                        border-radius: 5px;
                        padding: 5px 25px 5px 15px;
                        height: 43px;
                        background-color: #fff;
                        font-size: 1.3rem;
                        font-weight: 500;
                        letter-spacing: .02em;
                        color: #000;
                        @include mixins.max-screen(variables.$breakpoint-mobile) {
                            width: 100%;
                            max-width: unset;
                            font-size: 1.6rem;
                        }
                    }
                }
            }
            .next {
                width: 400px;
                max-width: 100%;
                height: 48px;
                margin: 100px auto 0;
                text-align: center;
                line-height: 48px;
                position: relative;
                @include mixins.max-screen(variables.$breakpoint-mobile) {
                    margin: 80px auto 0;
                }
                button[type="submit"] {
                    border-radius: 55px;
                    background-color: variables.$color-key;
                    color: variables.$color-font-base;
                    width: 100%;
                    font-size: 1.5rem;
                    letter-spacing: .04em;
                    position: relative;
                    margin: 0;
                    &[disabled] {
                        background-color: #9D9D9D;
                    }
                    &::after {
                        content: "";
                        background: url(~img/next-arrow.svg)center center no-repeat;
                        width: 11px;
                        height: 7px;
                        background-size: contain;
                        position: absolute;
                        top: 50%;
                        right: 17px;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
    .error-message{
        text-align: left;
        margin-top: 5px;
        color: red;
        font-size: 1.4rem;
        > span{
            margin-left: 0;
        }
    }
}
